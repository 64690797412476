import {graphql} from 'gatsby'
/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import styled from '@emotion/styled'

// Components
import Layout from "../components/layout"
import SEO from "../components/organisms/seo"
import TimeIndicator from "../components/molecules/TimeIndicator"
import Footer from '../components/organisms/footer';
import ContentOverflow from '../components/organisms/content-overflow';

// Images
import ImgMastheadAuthor from "../components/images/_masthead/img-masthead-author"
import Header1 from '../components/atoms/header-1';
import Masthead from '../components/organisms/masthead';
import Link1 from '../components/atoms/link-1';
import SocialShare from '../components/blog/SocialShare';
import ListNumbered1 from '../components/h31/ListNumbered1';
import ListBullet1 from '../components/h31/ListBullet1';
import Item1 from '../components/h31/Item1';


// Styles
const Spacer2 = styled.section`
  height: 250px;
  @media(max-width: 1100px) {
    height: 0px;
  }
`

const BlogHeader = styled.section`
  position: relative;
  width: calc(100% + 120px);
  margin-left: -60px;
  margin-top: -60px;
  padding-top: 30%;
  min-height: 100px;
`

const BlogHeaderTitle = styled.section`
  position: relative;
  width: calc(100% + 60px);
  margin-left: -60px;
  margin-bottom: 30px;
  padding: 30px;
  background: rgba(0, 27,68,0.86);
  @media (max-width: 1024px) {
    width: calc(100% + 90px);
    padding: 15px;
  }
  @media (max-width: 600px) {
    font-size: 80%;
  }
`

const BlogActionBar = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  width: 100%;
  background: transparent;
  @media (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 150px;
  }
`

const BlogContent = styled.section`
  position: relative;
  h1 {
    color: #8BC4A2;
    font-family: Dancing Script;
    font-size: 3em;
    font-weight: 800;
    margin: 20px 0;
    text-align: left;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  h2 {
    display: inline-block;
    color: #8BC4A2;
    font-family: Crimson Text;
    font-size: 2.5em;
    font-weight: 300;
    margin: 15px 0;
    text-align: left;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  p, li {
    line-height: 2;
    color: #777777;
    margin: 0 0 15px 0;
    font-size: 1.375em;
    font-family: Crimson Text;
    font-weight: 300;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  ol {
    ${ListNumbered1.__emotion_styles}
  }
  ul {
    ${ListBullet1.__emotion_styles}
  }
  li {
    ${Item1.__emotion_styles}
  }
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
  iframe {
    width: 100%;
    height: 433px;
    margin-bottom: 30px;
    @media (max-width: 992px) {
      height: 353px;
    }
    @media (max-width: 500px) {
      height: 265px;
    }
  }
`


const SocialWrapper = styled.section`
  width: 200px;
`


export default function BevsBlog({ data, pageContext }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  // // Extract articles for next and previous navigating
  // const { nextNext, next, previous, previousPrevious } = pageContext
  // let articles = [nextNext, next, previous, previousPrevious]
  // articles = articles.filter(article => article != null)

  // // Get data for this blog post
  // const dataNewBlogPost = {
  //   title: frontmatter.title,
  //   time: frontmatter.date,
  //   img: <img src={frontmatter.image} alt={frontmatter.title}/>,
  // }

  return (
    <Layout>
      <SEO title={`${frontmatter.title} | Beverly Hubbard`} description={markdownRemark.excerpt} />

      <Spacer2/>

      <ContentOverflow topOverflow='100px' bottomOverflow='0'>
        <BlogHeader
          css={css`
            background: url(${frontmatter.image}) no-repeat center/cover;
          `}
        >
        </BlogHeader>

        <BlogHeaderTitle>
          <Header1 spacing='0' color='#FFFFFF' align='center'>
            {frontmatter.title}
          </Header1>
        </BlogHeaderTitle>
        
        <BlogActionBar css={css`
          border-bottom: 2px solid #DADADA;
          padding-bottom: 30px;
          margin-bottom: 30px;
        `}>
          <Link1 href='/bevs-blog' color='#8BC4A2' fontSize='1.5rem'>
            {'Back to Blogs'}
          </Link1>

          <SocialWrapper>
            <SocialShare/>
          </SocialWrapper>

          <TimeIndicator time={frontmatter.date}/>
        </BlogActionBar>

        <BlogContent dangerouslySetInnerHTML={{ __html: html }}/>

        <BlogActionBar
          css={css`
            border-top: 2px solid #DADADA;
            padding-top: 30px;
            margin-top: 30px;
          `}
        >
          <Link1 href='/bevs-blog' color='#8BC4A2' fontSize='1.5rem'>
            {'Back to Blogs'}
          </Link1>

          <SocialWrapper>
            <SocialShare/>
          </SocialWrapper>

          <TimeIndicator time={frontmatter.date}/>
        </BlogActionBar>
      </ContentOverflow>
      <Footer/>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { path: { eq: $path } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date
        image
      }
    }
  }
`
